// Core
import React, { ReactElement } from "react";

// Components
import { AuthRedirect, PrivateRoute } from "@cpsq/auth-frontend";
import { AuthenticatedLayout } from "@cpsq/ui-components";

// Pages
import Dashboard from "./pages/dashboard/dashboard";
import Home from "./pages/home/home";

// Stylesheets
import "./assets/stylesheets/application.scss";

// Utils
import { Session, useAuth } from "@cpsq/auth-frontend";
import Api from "./utils/api";

// Vendor
import {
  faBars,
  faChartBar,
  faChartLine,
  faCog,
  faExternalLinkAlt,
  faHome,
  faLandmark,
  faSignInAlt,
  faSignOutAlt,
  faTasks,
  faTheaterMasks,
  faTimes,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

library.add(
  faBars,
  faChartBar,
  faChartLine,
  faCog,
  faExternalLinkAlt,
  faHome,
  faLandmark,
  faSignInAlt,
  faSignOutAlt,
  faTasks,
  faTheaterMasks,
  faTimes,
  faUserCircle
);

const App = (): ReactElement => {
  const { isAuthenticated, isInternalAdmin, signOut, getToken } = useAuth();

  Api.getAuthToken = getToken;
  const serviceName = "welcome";

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route
          exact
          path="/callback/"
          render={(props): ReactElement => (
            <AuthRedirect {...props} serviceName={serviceName} />
          )}
        />
        <Route
          render={(props): ReactElement => {
            return !isAuthenticated ? (
              <></>
            ) : (
              <AuthenticatedLayout
                getCentre={Api.getCentre}
                getCentreFeatures={Api.getCentreFeatures}
                heading=""
                isAuthenticated={isAuthenticated}
                isInternalAdmin={isInternalAdmin}
                serviceName={serviceName}
                Session={Session}
                signOut={signOut}
                token={getToken()}
                {...props}
              >
                <PrivateRoute path="/dashboard/" component={Dashboard} />
              </AuthenticatedLayout>
            );
          }}
        />
      </Switch>
    </Router>
  );
};

export default App;
