// Core
import React from "react";

// Components
import App from "./App";

// Utils
import { OAuthProvider } from "@cpsq/auth-frontend";
import { gtmConfig, OAuthConfig } from "./utils/config";

// Vendor
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";

// Styling
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import {
  createGlobalStyle as GlobalStyle,
  mainTheme
} from "@cambridgeassessment/cambridge-ui";

const tagManagerArgs = {
  gtmId: gtmConfig.gtmId
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <OAuthProvider
    authority={`${OAuthConfig.authorirty}/authorize`}
    redirectUri={OAuthConfig.redirectUri}
    clientId={OAuthConfig.clientId}
    autoSignIn={false}
  >
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </OAuthProvider>,
  document.getElementById("root")
);
